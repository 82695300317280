import { isServer } from '@lib/utils/is-server'
import { ApolloLink } from '@apollo/client'
import { getAccessToken } from '@lib/utils/access-token'
import { createUploadLink } from 'apollo-upload-client'

export const httpLink = createUploadLink({
  uri: `${process.env.NEXT_PUBLIC_SERVER_HOST}/graphql`,
  credentials: 'include',
  // 참고: https://www.apollographql.com/docs/apollo-server/data/file-uploads/
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
}) as unknown as ApolloLink

export const authMiddleware = (serverAccessToken?: string) =>
  new ApolloLink((operation, forward) => {
    const accessToken = isServer() ? serverAccessToken : getAccessToken()

    operation.setContext({
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    })

    return forward(operation)
  })
