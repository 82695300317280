export const colors = {
  black: '#000000',
  white: '#ffffff',
  bg: '#FCFCFC',
  transparent: 'transparent',
  lightBlue: '#1F57F7',

  // gray
  gray50: '#F6F8F9',
  gray100: '#E9EDEF',
  gray200: '#D6DDE0',
  gray300: '#CBCBCB',
  gray400: '#AFAFAF',
  gray500: '#757575',
  gray600: '#545454',
  gray700: '#333333',
  gray800: '#1F1F1F',
  gray900: '#141414',

  //  blue
  blue50: '#EFF3FE',
  blue100: '#D4E2FC',
  blue200: '#A0BFF8',
  blue300: '#5B91F5',
  blue400: '#0039FF',
  blue500: '#0A34C3',
  blue600: '#0027AB',
  blue700: '#001A74',

  // green
  green50: '#E6F2ED',
  green100: '#ADDEC9',
  green200: '#66D19E',
  green300: '#06C167',
  green400: '#0EAD44',
  green500: '#03703C',
  green600: '#03582F',
  green700: '#10462D',

  // yellow
  yellow50: '#FFFAF0',
  yellow100: '#FFF2D9',
  yellow200: '#FFE3AC',
  yellow300: '#FFCF70',
  yellow400: '#FFC043',
  yellow500: '#F2A610',
  yellow600: '#997328',
  yellow700: '#674D1B',

  // orange
  orange50: '#FFF3EF',
  orange100: '#FFE1D6',
  orange200: '#FABDA5',
  orange300: '#FA9269',
  orange400: '#FF6937',
  orange500: '#C14F29',
  orange600: '#9A3F21',
  orange700: '#672A16',

  // red
  red50: '#FFEFED',
  red100: '#FED7D2',
  red200: '#F1998E',
  red300: '#E85C4A',
  red400: '#E11900',
  red500: '#AB1300',
  red600: '#870F00',
  red700: '#5A0A00',

  // purple
  purple50: '#F4F1FA',
  purple100: '#E3DDF2',
  purple200: '#C1B5E3',
  purple300: '#957FCE',
  purple400: '#7356BF',
  purple500: '#574191',
  purple600: '#453473',
  purple700: '#2E224C',
} as const

export type ColorType = typeof colors[keyof typeof colors]
