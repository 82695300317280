import { Client, Server } from 'styletron-engine-atomic'

type HydrateT =
  | HTMLCollectionOf<HTMLStyleElement>
  | Array<HTMLStyleElement>
  | NodeListOf<HTMLStyleElement>

const getHydrateClass = () =>
  document.getElementsByClassName('_styletron_hydrate_')

export const styletronEngine =
  typeof window === 'undefined'
    ? new Server()
    : new Client({
        hydrate: getHydrateClass() as HydrateT,
      })
