import { onError } from '@apollo/client/link/error'

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
        switch (extensions.code) {
          case 'BAD_USER_INPUT':
            console.log('유효하지 않은 유저입니다.')
            break
          case 'UNAUTHENTICATED':
            console.log('로그인이 필요합니다.')
            break
          default:
            break
        }
      })
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  }
)
