import { Breakpoints } from 'baseui/theme'
import { useEffect, useState } from 'react'
import { DeviceType } from './types'

export const breakpointsTheme: Breakpoints = {
  small: 0,
  medium: 768,
  large: 1136,
}

export const breakpoints: Record<DeviceType, number> = {
  MOBILE: breakpointsTheme.small,
  TABLET: breakpointsTheme.medium,
  DESKTOP: breakpointsTheme.large,
}

export const useBreakpoint = (breakpoint: string) => {
  // Get the media query to match
  const query = breakpoint.replace(/^@media\s*/, '')
  const [isBreak, setIsBreak] = useState<boolean | null>(null)

  useEffect(() => {
    const mq = window.matchMedia(query)
    const handleChange = (event: MediaQueryListEvent) => {
      setIsBreak(event.matches)
    }

    setIsBreak(mq.matches)

    // Safari < 14 can't use addEventListener on a MediaQueryList
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
    if (!mq.addEventListener) {
      // Update the state whenever the media query match state changes
      mq.addListener(handleChange)

      // Clean up on unmount and if the query changes
      return () => {
        mq.removeListener(handleChange)
      }
    }
    mq.addEventListener('change', handleChange)

    return () => {
      mq.removeEventListener('change', handleChange)
    }
  }, [query])

  return isBreak
}
