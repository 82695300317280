import jwtDecode from 'jwt-decode'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { getAccessToken, setAccessToken } from './../../utils/access-token'
import { isServer } from '@lib/utils/is-server'

export const refreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const accessToken = getAccessToken()

    // server에서는 variables에 저장된 access token을 사용하지 않기 때문에 여기서는 검사하지 않음
    if (isServer()) return true

    // TODO: Refresh redirct error 처리 필요
    if (!accessToken) return false

    try {
      const isAccessTokenValid = Boolean(
        accessToken &&
          jwtDecode<{ exp: number }>(accessToken).exp * 1000 > Date.now()
      )

      // access_token이 없거나 유효기간 만료 시 fetchAccessToken 호출
      if (!isAccessTokenValid) return false
      return true
    } catch {
      return false
    }
  },
  // isTokenValidOrUndefined이 false일 때 실행되는 함수
  fetchAccessToken: async () => {
    return fetch(`${process.env.NEXT_PUBLIC_SERVER_HOST}/jwt/refresh`, {
      method: 'GET',
      credentials: 'include',
    })
  },
  // fetchAccessToken이 성공하면 실행되는 함수
  handleFetch: (accessToken) => {
    setAccessToken(accessToken)
  },
  // fetchAccessToken이 실패하면 실행되는 함수
  handleError: (err) => {
    if (getAccessToken()) {
      console.error(err)
      console.warn('유효하지 않은 토큰입니다. 다시 로그인해주세요.')
    }
    setAccessToken('')
  },
})
