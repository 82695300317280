import { getAccessToken } from '@lib/utils/access-token'
import { TypePolicies } from '@apollo/client/cache/inmemory/policies'
import { makeVar } from '@apollo/client'

const accessToken = getAccessToken()
const isLoggedInVar = makeVar(Boolean(accessToken))

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      isLoggedIn: {
        read() {
          return isLoggedInVar()
        },
      },
      getEarningsByCursor: {
        read(existing: any) {
          return existing
        },
        merge(existing, incoming, { args }) {
          if (!args?.before) return incoming
          const existingEdges = existing?.edges || []
          return {
            ...existing,
            pageInfo: incoming.pageInfo,
            edges: [...existingEdges, ...incoming?.edges],
          }
        },
      },
      getEarningTotalAmountsByCursor: {
        read(existing: any) {
          return existing
        },
        merge(existing, incoming, { args }) {
          if (!args?.before) return incoming
          const existingEdges = existing?.edges || []
          return {
            ...existing,
            pageInfo: incoming.pageInfo,
            edges: [...existingEdges, ...incoming?.edges],
          }
        },
      },
      getFaqsByOffset: {
        read(existing: any) {
          return existing
        },
        merge(existing, incoming, { args }) {},
      },
    },
  },
}
