export const typography = {
  HeadingXXL: {
    fontSize: '96px',
    lineHeight: '112px',
    fontFamily: 'Pretendard Variable',
  },
  HeadingXL: {
    fontSize: '52px',
    lineHeight: '64px',
    fontFamily: 'Pretendard Variable',
  },
  HeadingL: {
    fontSize: '40px',
    lineHeight: '52px',
    fontFamily: 'Pretendard Variable',
  },
  HeadingM: {
    fontSize: '32px',
    lineHeight: '40px',
    fontFamily: 'Pretendard Variable',
  },
  HeadingS: {
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: 'Pretendard Variable',
  },
  HeadingXS: {
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: 'Pretendard Variable',
  },
  LabelL: {
    fontSize: '18px',
    lineHeight: '24px',
    fontFamily: 'Pretendard Variable',
  },
  LabelM: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Pretendard Variable',
  },
  LabelS: {
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Pretendard Variable',
  },
  LabelXS: {
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Pretendard Variable',
  },
  ParagraphL: {
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: 'Pretendard Variable',
  },
  ParagraphM: {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Pretendard Variable',
  },
  ParagraphS: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Pretendard Variable',
  },
  ParagraphXS: {
    fontSize: '12px',
    lineHeight: '20px',
    fontFamily: 'Pretendard Variable',
  },
  TabletHeadingXXL: {
    fontSize: '64px',
    lineHeight: '72px',
    fontFamily: 'Pretendard Variable',
  },
  TabletHeadingXL: {
    fontSize: '44px',
    lineHeight: '52px',
    fontFamily: 'Pretendard Variable',
  },
  TabletHeadingL: {
    fontSize: '36px',
    lineHeight: '44px',
    fontFamily: 'Pretendard Variable',
  },
  TabletHeadingM: {
    fontSize: '28px',
    lineHeight: '36px',
    fontFamily: 'Pretendard Variable',
  },
  MobileHeadingXXL: {
    fontSize: '52px',
    lineHeight: '64px',
    fontFamily: 'Pretendard Variable',
  },
  MobileHeadingXL: {
    fontSize: '40px',
    lineHeight: '48px',
    fontFamily: 'Pretendard Variable',
  },
  MobileHeadingL: {
    fontSize: '32px',
    lineHeight: '40px',
    fontFamily: 'Pretendard Variable',
  },
  MobileHeadingM: {
    fontSize: '28px',
    lineHeight: '36px',
    fontFamily: 'Pretendard Variable',
  },
} as const
