import { createTheme } from 'baseui'
import { Theme } from 'baseui/theme'
import { breakpointsTheme } from './breakpoints'
import { mediaQueryTheme } from './media-query'
import { typography } from './typography'

export const defaultTheme = createTheme({})

export const theme: Theme = {
  ...defaultTheme,
  grid: {
    columns: [4, 8, 12],
    gutters: [16, 16, 16],
    margins: [16, 32, 32],
    gaps: 0,
    maxWidth: 960,
    unit: 'px',
  },
  breakpoints: breakpointsTheme,
  mediaQuery: mediaQueryTheme,
  typography: {
    ...defaultTheme.typography,
    ...typography,
  },
}
