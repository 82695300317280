import { MediaQuery } from 'baseui/theme'
import { breakpointsTheme } from './breakpoints'
import { DeviceType } from './types'

export const mediaQueryTheme: MediaQuery = {
  small: `@media screen and (min-width: ${breakpointsTheme.small}px)`,
  medium: `@media screen and (min-width: ${breakpointsTheme.medium}px)`,
  large: `@media screen and (min-width: ${breakpointsTheme.large}px)`,
}

export const mediaQuery: Record<DeviceType, string> = {
  MOBILE: mediaQueryTheme.small,
  TABLET: mediaQueryTheme.medium,
  DESKTOP: mediaQueryTheme.large,
}
