import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { gtag } from '..'

export const useGAPageView = () => {
  const router = useRouter()

  // 이벤트(유입, 이탈) 유형에 따라 페이지 조회수 측정
  useEffect(() => {
    if (gtag.GA_TRACKING_ID) {
      const handleRouteChange = (url: string) => {
        gtag.pageview(url)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      router.events.on('hashChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
        router.events.off('hashChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])
}
