import { ApolloPageProps } from './types'
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { isServer } from '@lib/utils/is-server'
import { authMiddleware, httpLink } from './links/http.link'
import { typePolicies } from './type-policies'
import { setAccessToken } from '@lib/utils/access-token'
import { useMemo } from 'react'
import { APOLLO_SERVER_ACCESS_TOKEN, APOLLO_STATE_PROP_NAME } from './constants'
import { refreshLink } from './links/refresh.link'
import { errorLink } from './links/error.link'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

const createApolloClient = (serverAccessToken?: string) =>
  new ApolloClient({
    ssrMode: isServer(),
    link: ApolloLink.from([
      errorLink,
      authMiddleware(serverAccessToken),
      refreshLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      typePolicies,
    }),
  })

export const initializeApollo = (
  initialState: NormalizedCacheObject | null = null,
  serverAccessToken?: string
) => {
  const _apolloClient = apolloClient ?? createApolloClient(serverAccessToken)

  // 페이지가 data fetching methods를 가진다면 initial state는 이곳에서 hydrated 됨
  if (initialState) {
    const existingCache = _apolloClient.extract()

    // 기존 cache에 있는 getStaticProps/getServerSideProps에서 initialState 병합
    const data: NormalizedCacheObject = merge(existingCache, initialState, {
      arrayMerge: (
        destinationArray: NormalizedCacheObject[],
        sourceArray: NormalizedCacheObject[]
      ) => {
        return [
          ...sourceArray,
          ...destinationArray.filter((d) =>
            sourceArray.every((s) => !isEqual(d, s))
          ),
        ]
      },
    })

    // 병합된 데이터로 캐시 복구
    _apolloClient.cache.restore(data)
  }

  // SSG, SSR에서는 항상 새로운 Apollo Client 생성
  if (isServer()) return _apolloClient

  // CSR에서 Apollo Client 한 번만 생성
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const useApollo = (pageProps: ApolloPageProps) => {
  const initialState = pageProps[APOLLO_STATE_PROP_NAME]
  const accessToken = pageProps[APOLLO_SERVER_ACCESS_TOKEN]

  if (accessToken) {
    setAccessToken(accessToken)
  }
  const client = useMemo(
    () => initializeApollo(initialState, accessToken),
    [accessToken, initialState]
  )
  return client
}
