import '../styles/globals.css'
import '../styles/css/nprogress.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import { theme } from '@styles/theme'
import { styletronEngine } from '@styles/styletron-engine'
import { NextPage } from 'next'
import { useEffect } from 'react'
import { getScrollbarWidth } from '@lib/utils/get-scroll-bar-width'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '@lib/apollo/apollo-client'
import { colors } from '@styles/colors'
import { useNprogress } from '@lib/hooks/use-ngrogress'
import { DefaultSeo, DefaultSeoProps } from 'next-seo'
import Script from 'next/script'
import { gtag } from '@lib/ga'
import { useGAPageView } from '@lib/ga/hooks/use-page-view'

type MyAppProps = AppProps & {
  Component: NextPage
}

function MyApp({ Component, pageProps }: MyAppProps) {
  const client = useApollo(pageProps)

  const getLayout = Component.getLayout || ((page) => page)

  useEffect(() => {
    document.documentElement.setAttribute(
      'style',
      '--scrollbar-width: ' + getScrollbarWidth() + 'px'
    )
  }, [])

  useNprogress()
  useGAPageView()

  return (
    <>
      {gtag.GA_TRACKING_ID && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtag.GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
          `,
            }}
          />
        </>
      )}
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicons/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicons/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicons/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicons/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicons/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicons/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicons/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicons/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicons/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicons/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content={colors.blue400} />
        <meta
          name="msapplication-config"
          content="/favicons/browserconfig.xml"
        />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content={colors.blue400} />
      </Head>
      <DefaultSeo {...DEFAULT_SEO} />
      <ApolloProvider client={client}>
        <StyletronProvider value={styletronEngine}>
          <BaseProvider theme={theme}>
            {getLayout(<Component {...pageProps} />)}
          </BaseProvider>
        </StyletronProvider>
      </ApolloProvider>
    </>
  )
}

export default MyApp

const DEFAULT_SEO: DefaultSeoProps = {
  title: 'Ontol',
  description:
    '검사 결과지, 쉽고 빠르게 해석 받으세요. MRI, CT, 초음파, 조직 검사. 어려운 의학용어를 이해하기 편한 한글로 바꿔드려요.',
  canonical: 'https://ontol.com',

  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: 'https://ontol.com',
    title: '온톨 - 검사 결과지, 쉽고 빠르게 해석 받으세요',
    site_name: '온톨 Ontol',
    images: [
      {
        url: 'https://images.ontol.com/images/ogimage/ontol-ogt.png',
        width: 285,
        height: 167,
        alt: 'ontol',
        type: 'image/png',
      },
    ],
  },
}
